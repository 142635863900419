import * as React from "react"

import "../styles/theme.min.css"

export default function Board({ children }) {
 return (
    <>
   <div className="staff-table-container">	
	   	<div className="row py-2">
		 <div className="col-12 col-md-12">
     	{children}
	    </div>
	   </div>
   </div>
   </>
 )	  
}