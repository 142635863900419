import Staff from "../../../../src/components/staff";
import Board from "../../../../src/components/board";
import Committee from "../../../../src/components/committee";
import WorkingGroup from "../../../../src/components/working-group";
import * as React from 'react';
export default {
  Staff,
  Board,
  Committee,
  WorkingGroup,
  React
};